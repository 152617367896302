import React from 'react'

import './App.css'
import { LOBBY, USER_CONNECTED, USER_DISCONNECTED } from './events'
import logo from './logo.svg'
import Topics from './components/Topics'
import useLocalStorage from './hooks/useLocalStorage'
import Users from './components/Users'
import useTopics from './hooks/useTopics'

import { emit, listen } from './SocketService'

const App = () => {
  const [user, setUser] = React.useState()
  const [username, setUsername] = useLocalStorage('username', '')
  const [userList, setUserList] = React.useState([])
  const userListRef = React.useRef([])
  const topics = useTopics()

  const joinRoom = (username) => {
    const roomId = window.location.pathname.substring(1) || 'testRoom'
    emit(USER_CONNECTED, { username, roomId })
  }

  const login = () => {
    if (user) {
      setUsername(user)
      joinRoom(user)
    }
  }

  const keyPress = e => {
    if (e.keyCode === 13) {
      login()
    }
  }

  React.useEffect(() => {
    // get all users in the lobby
    listen(LOBBY, lobby => {
      userListRef.current = lobby
      setUserList(userListRef.current.sort())
    })

    // listen for user connects
    listen(USER_CONNECTED, newUser => {
      userListRef.current = userListRef.current.concat([newUser])
      setUserList(userListRef.current.sort())
    })

    // listen for user disconnects
    listen(USER_DISCONNECTED, userToRemove => {
      userListRef.current = userListRef.current.filter(user => user !== userToRemove)
      setUserList(userListRef.current.sort())
    })

    // tell everyone i'm connected
    if (username) {
      joinRoom(username)
    }
  }, [])

  return (
    <React.Fragment>
      {!username && (
        <div className="App">
          <header className="App-header">
            <h4>Fat Water Cooler</h4>
            <img src={logo} className="App-logo" alt="logo" />
            <React.Fragment>
              <h4>What shall we call thee at the fat water cooler?</h4>
              <input type="text" placeholder="" onChange={e => setUser(e.target.value)} onKeyDown={keyPress} />
              <div className="button" onClick={login} style={{ marginTop: '20px' }}>
                Enter
              </div>
            </React.Fragment>
          </header>
        </div>
      )}
      {username && (
        <div className="grid">
          <Topics topics={topics} />
          <div className="current-topic">current-topic</div>
          <Users userList={userList} self={username} />
          <div className="time-controls">time-controls</div>
          <div className="water-cooler">water-cooler</div>
        </div>
      )}
    </React.Fragment>
  )
}
export default App
