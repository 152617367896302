import openSocket from 'socket.io-client'

// TODO: SOCKET_URL should be an env var
const socketUrl = 'http://localhost:8080'

let socket = openSocket(socketUrl)

const emit = (eventKey, data) => {
  console.log(`emitting ${eventKey}`, data)
  socket.emit(eventKey, data)
}

const listen = (eventKey, callback) => {
  console.log(`listening ${eventKey}`)
  socket.on(eventKey, callback)
}

export { emit, listen }
