import React from 'react'

import { emit } from '../SocketService'
import { TOPIC_CREATED } from '../events'
import useTopics from '../hooks/useTopics'

import './Topics.css'

const TopicList = ({ topics }) => {
  return (
    <div className="topic-list">
      {topics.map((topic, i) => {
        // topics will have a voteCount, description, and createdBy???
        return (
          <div key={i}>
            <p>{topic.description}</p>
            <p>{topic.voteCount}</p>
            <p>{topic.createdBy}</p>
          </div>
        )
      })}
    </div>
  )
}

const TopicInput = () => {
  const inputRef = React.useRef()
  const [willSubmit, setWillSubmit] = React.useState(false)

  React.useEffect(() => {
    if (!willSubmit) return
    emit(TOPIC_CREATED, inputRef.current.innerText)
    setWillSubmit(false)
    inputRef.current.innerText = ''
  }, [willSubmit])

  const keyPress = e => e.keyCode === 13 && setWillSubmit(true)

  return (
    <div
      ref={inputRef}
      type="textarea"
      onKeyDown={keyPress}
      className="textareaElement"
      placeholder="Topics go here!"
      contentEditable
    />
  )
}

const Topics = () => {
  const topics = useTopics()

  // topics is in charge of 2 things
  // 1) list all the topics
  // 2) input for new topics
  return (
    <div className="topics">
      <TopicList topics={topics} />
      <TopicInput />
    </div>
  )
}

export default Topics
