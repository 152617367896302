import React from 'react'

const Users = ({ userList, self }) => {
  return (
    <div className="users">
      {userList.map((u, i) => {
        const isMe = u === self
        return (
          <p className={isMe ? 'me' : undefined} key={i}>
            {u}
          </p>
        )
      })}
    </div>
  )
}

export default Users
