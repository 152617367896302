import React from 'react'

import { listen } from '../SocketService'
import { TOPICS, TOPIC_CREATED } from '../events'

const useTopics = () => {
  const [topics, setTopics] = React.useState([])
  const topicsRef = React.useRef([])

  React.useEffect(() => {
    // listen for initial list of topics
    listen(TOPICS, topics => {
      topicsRef.current = topics
      setTopics(topicsRef.current.sort())
    })

    // listen for new topic
    listen(TOPIC_CREATED, topic => {
      topicsRef.current = topicsRef.current.concat([topic])
      setTopics(topicsRef.current)
    })
  }, [])

  return topics
}

export default useTopics
