module.exports = {
  LOBBY: 'LOBBY',
  TIME_LEFT: 'TIME_LEFT',
  TIMER_RESET: 'TIMER_RESET',
  TIMER_STARTED: 'TIMER_STARTED',
  TIMER_STATUS: 'TIMER_STATUS',
  TIMER_STOPPED: 'TIMER_STOPPED',
  TOPIC_CREATED: 'TOPIC_CREATED',
  TOPIC_UPDATED: 'TOPIC_UPDATED',
  TOPICS: 'TOPICS',
  TOTAL_TIME: 'TOTAL_TIME',
  USER_CONNECTED: 'USER_CONNECTED',
  USER_DISCONNECTED: 'USER_DISCONNECTED',
}
